import React from 'react'
import Navbar from '../Components/Navbar'

function WorkinProgress() {
    return (
        <>
            <Navbar />
            <div className='w-[100%] min-h-[80vh] h-full flex flex-col gap-[10px] items-center mt-[60px] md:mt-[125px]  font-semibold px-[20px] md:px-[100px] '>
                <p className='text-[22px] md:text-[24px] mb-[16px] underline'>Unlock Investment Potential</p>
                <p className='font-normal mb-[16px]'>Welcome to INVESTCALLS.COM, your passport to investment success. Whether you're a startup or an established tech player, we provide a global platform to showcase your innovative products. Benefit from exposure at IT Expos worldwide, expert evaluation, and connections with potential investors. Our partnership with Eguidance Labs LLP, a software product startup, ensures credibility and support for your journey.</p>
                <div className='border-[1px] border-[#0BB245] rounded-[12px] p-[8px] md:p-[16px]'>
                    <p className='text-center font-normal'>Getting started is simple. <br /> Email us at <span className='text-cyan-400'> info@eguidancelabs.com</span>  with the subject &quot;INVESTCALLS&quot; <br /> or reach out via WhatsApp at <span className='text-cyan-400'>+919567183183</span> . <br /> Don't miss this opportunity to secure investments and shine on the global stage. &#55357;&#56960;</p>
                </div>
            </div>
        </>
    )
}

export default WorkinProgress