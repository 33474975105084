import React, { useState } from 'react'
import ReactPlayer from 'react-player';



function Products({ data }) {
  console.log("data", data);
  const [readMore, setReadMore] = useState(false)


  return (

    <div className={`flex flex-col py-[30px] px-[22px] mt-[20px] w-[100%]  rounded-[12px] items-start md:pt-[80px] md:px-[60px] md:mt-[60px] md:rounded-[20px]`}
      style={{ background: data?.backgroundColour }}>
      <div className=' flex flex-col gap-[30px] mb-[20px] md:flex md:flex-row md:gap-[103px] md:mb-[60px]'>
        <div className='md:w-1/2'>
          <div className='flex flex-col  gap-[8px] md:flex-row md:items-center md:gap-[16px] '>
            <img src={data?.productLogo} alt="" className=' w-[60px] h-[60px] md:w-[100px] md:h-[100px] object-contain' />
            <div className='flex flex-col gap-[8px]'>
              <h1 className='text-[18px] font-semibold leading-[25px] md:text-[24px] md:leading-[33px]'>
                {data?.product}
              </h1>
              <p className='text-[16px] font-normal leading-[22px]'>
                {data?.shortDescription}
              </p>
            </div>
          </div>
          <div className='flex flex-col mt-[20px] gap-[8px] md:mt-[60px] md:gap-[16px] '>
            <h1 className='text-[18px] font-medium leading-[25px] md:text-[24px] md:leading-[33px] '>
              {data?.header}
            </h1>
            <p className={` text-[14px] font-medium leading-[20px] md:text-[16px] md:leading-[22px]`}
              onClick={() => setReadMore((prev) => !prev)}
            >
              {readMore ? data?.description : data?.description.slice(0, 200)}  {!readMore && data?.description?.length > 200 && <span className='textMediumBlue'> Read more...</span>}
            </p>
          </div>
        </div>
        <div className='md:w-1/2 h-[330px] '>
          <ReactPlayer
            config={{
              file: { attributes: { controlsList: "nodownload" } },
            }}
            playing={false}
            controls={true}
            width="100%"
            height="100%"
            url={data?.productVideo}
          />
        </div>
      </div>
      <div className='seperator mb-[30px]' />
      <div className=' w-[100%] flex flex-col gap-[30px] mb-[30px] md:gap-[130px] md:flex-row'>
        <div>
          <p className='textSemiBold'>Website</p>
          <a href={`https://${data?.website}`} target="_blank" className='textMediumBlue'>{data?.website}</a>
        </div>
        <div>
          <p className='textSemiBold'>Product Status</p>
          <p className='textMedium'>{data?.status}</p>
        </div>
        <div>
          <a href={`${data?.pitchdeskRedirect}`} target="_blank">
            <button
              className='px-[16px] py-[12px] text-[#0A66C2] rounded-[6px] border-[1px] border-solid border-[#0A66C2]'>
              Download Pitchdeck
            </button>
          </a>

        </div>
      </div>
      <div className='seperator mb-[30px]' />
      <div className='w-[100%]'>
        <h1 className='textSemiBoldLarge'>Investment details</h1>
        <div className='w-[100%] mt-[10px] flex flex-col gap-[30px] mb-[30px] md:mt-[16px] md:flex-row md:justify-between'>
          <div>
            <p>FUNDING STAGE</p>
            <p className='textSemiBold'>{data?.investmentDetails?.funding}</p>
          </div>
          <div>
            <p>ROI-MINIMUM EXPECTED REVENUE</p>
            {
              data?.investmentDetails?.roi?.map((element) => {
                return (
                  <p className='textSemiBold'>{element}</p>
                )
              })
            }
          </div>
          <div>
            <p>MIN. EXPECTED INVESTMENT</p>
            <p className='textSemiBold'>{data?.investmentDetails?.expectedInvestment}</p>
          </div>
          <div>
            <p>NO. OF SHARE HOLDERS</p>
            <p className='textSemiBold'>{data?.investmentDetails?.noOfShareHolders}</p>
          </div>
        </div>
      </div>
      <div className='seperator mb-[30px]' />

    </div>

  )
}

export default Products