import React, { useEffect, useState } from 'react'
import Landing from '../Components/Landing'
import Navbar from '../Components/Navbar'
import Companies from '../Components/Companies'

function Home() {

    return (
        <div className='pb-[30px]'>
            <Navbar />
            <Landing />
            <Companies />
        </div>
    )
}

export default Home