import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import "./App.css";
import InvestorsCorner from "./Pages/InvestorsCorner";
import WorkinProgress from "./Pages/WorkinProgress";

function App() {
  return (
    <div className="App ">
      <div className="px-[20px] md:px-[70px] max-w-[1440px] mx-auto">
        <Routes>
          <Route index element={<Home />} />
          <Route path="/:company" element={<InvestorsCorner />} />
          <Route path="/investors" element={<WorkinProgress />} />
          <Route path="/company" element={<WorkinProgress />} />
        </Routes>
      </div>
      <div className="w-full text-center mt-[20px] md:mt-[40px] bg-[#0BB245] py-[20px] md:py-[24px]">
        <p className="text-white font-semibold">
          Powered By <br />
          www.eguidancelabs.com
        </p>
      </div>
    </div>
  );
}

export default App;
