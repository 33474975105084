import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Products from '../Components/Products';
import { useParams } from 'react-router-dom'
import Navbar from '../Components/Navbar';



function InvestorsCorner() {
    const [jsonData, setJsonData] = useState([])
    const { company } = useParams();
    const [readMoreCompany, setreadMoreCompany] = useState(false)

    console.log("jsonData", jsonData);
    console.log("company", company);



    useEffect(() => {
        getProducts()
    }, [])

    const getProducts = async () => {
        try {
            const data = await axios.get(`/Json/${company}.json`);
            { console.log("data", data); }
            setJsonData(data?.data);
        } catch (err) {
            console.log(err.message);
        }
    };
    return (
        <div>
            <Navbar />
            <div className='mt-[60px]'>
                <div className='flex flex-col'>
                    <div className='flex flex-col mt-[10px] mb-[20px] md:flex-row  md:mt-[20px] md:mb-[30px]'>
                        <div className='flex flex-col md:w-1/2 md:mr-[110px]'>
                            <div className='mb-[20px] md:mb-[20px]' >
                                <div className='flex  md:flex-row flex-col gap-[8px] md:gap-[16px] md:items-center  md:mb-[16px]'>
                                    <img src={jsonData?.companyLogo} alt="" className='w-[60px] h-[60px]' />
                                    <h1 className=' mb-[6px] md:mb-[12px] textSemiBoldLarge text-[#151515]'>{jsonData?.companyName}</h1>
                                </div>
                                <p className='textMedium'
                                    onClick={() => setreadMoreCompany((prev) => !prev)}
                                >
                                    {readMoreCompany ? jsonData?.companyDescription : jsonData?.companyDescription?.slice(0, 250)}
                                    {!readMoreCompany && jsonData?.companyDescription?.length > 250 && <span className='textMediumBlue'> Read More...</span>}
                                </p>
                            </div>
                            <div className='mb-[20px] md:mb-[20px]'>
                                <h1 className='mb-[6px] textSemiBold'>Website</h1>
                                <a href={`https://${jsonData?.companyWebsite}`} target="_blank" className='textMediumBlue'>{jsonData?.companyWebsite}</a>
                            </div>
                            <div className='mb-[20px] md:mb-[20px]'>
                                <h1 className='mb-[6px] textSemiBold'>Email</h1>
                                <p className='textMedium'>{jsonData?.companyEmail}</p>
                            </div>
                            <div className='mb-[20px] md:mb-[20px]'>
                                <h1 className='mb-[6px] textSemiBold'>WhapsApp/Mobile</h1>
                                <p className='textMedium'>{jsonData?.companyMobile}</p>
                            </div>
                        </div>
                        <div className='flex flex-col  md:w-1/2'>
                            <div className='mb-[20px] md:mb-[30px]' >
                                <h1 className='mb-[6px] textMediumGrey'>Company Size</h1>
                                <p className='textMedium'>{jsonData?.companySize}</p>
                            </div>
                            <div className='mb-[20px] md:mb-[30px]'>
                                <h1 className='mb-[6px] textMediumGrey'>Company Type</h1>
                                <p className='textMedium'>{jsonData?.companyType}</p>
                            </div>
                            <div>
                                <h1 className='textMediumGrey mb-[10px]'>Founder(s)</h1>
                                {
                                    jsonData?.founders?.map((obj) => {
                                        return (
                                            <div className='bg-white p-[10px] max-w-[390px] w-[100%] flex gap-[12px] rounded-[6px]'>
                                                <div>
                                                    <img src={obj?.image} alt="image" className='w-[70px] h-[70px] object-cover' />
                                                </div>
                                                <div className='flex flex-col'>
                                                    <h1 className='textSemiBold'>{obj?.name}</h1>
                                                    <p className='textMediumShort text-[#666666]'>{obj?.tagLine}</p>
                                                    <p className='textMediumShort text-[#9194A0]'>{obj?.location}</p>
                                                </div>
                                            </div>

                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    {/* <div>
                        <h1 className='mb-[6px] textSemiBold'>{jsonData?.companyName}</h1>
                        <p className='textMediumGrey'>{jsonData?.companyAddress}</p>
                    </div> */}
                </div>
            </div>
            <div className='h-[1px] w-full bg-black opacity-[0.5] mb-[30px]'></div>
            <div>
                <p className='mb-[6px] font-semibold  text-[24px] md:text-[32px]'>Products Inviting Investment</p>
            </div>
            {
                jsonData?.product?.map((obj) => {
                    return (
                        <Products data={obj} />
                    )
                })
            }
        </div>
    )
}

export default InvestorsCorner