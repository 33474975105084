import React from 'react'
import styles from './styles/styles.module.css'
import newLogo1 from '../Assets/Images/newLogo1.svg'
import hamburger from '../Assets/Images/hamburger.svg'

function Navbar() {
    return (
        <div className="flex items-center justify-between">
            {/* <!-- Logo --> */}
            <div className="pt-2 flex items-center py-[4px] md:py-[6px]">
                <a href="/">
                    <img src={newLogo1} alt="logo" className='h-[54px] md:h-[59px] ' />
                </a>
                {/* <h1 className='text-[18px] leading-[22px] font-semibold'>Investcalls</h1> */}
            </div>
            {/* <!-- Menu Items --> */}
            <div className='flex items-center'>
                {/* <!-- Button --> */}
                <a href="/company"
                    className=" px-[12px] py-[6px] md:px-[22px] md:py-[12px] text-white font-semibold text-[12px] md:text-[16px] leading-[19px] bg-[#0BB245] rounded-[5px] baseline hover:bg-[#0C8A38] md:block">Join as Company</a>
                {/* <!-- Hamburger Icon --> */}
                {/* <button className="block md:hidden focus:outline-none">
                    <img src={hamburger} alt="hamburger" />
                </button> */}
            </div>

        </div>
    )
}

export default Navbar