import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import egLabsLogo from '../Assets/Images/egLabsLogo.svg'
import styles from './styles/styles.module.css'

function Companies() {

    const [companyData, setCompanyData] = useState([])
    console.log("companyData", companyData);

    useEffect(() => {
        getCompanies()
    }, [])

    const getCompanies = async () => {
        try {
            const data = await axios.get(`/Json/Companies.json`);
            { console.log("data", data); }
            setCompanyData(data?.data);
        } catch (err) {
            console.log(err.message);
        }
    };

    return (
        <div className='w-[100%] max-w-[565px]'>
            <div className='mb-[30px]'>
                <h1 className='font-medium text-[36px] leading-[49px]'>Companies looking for investment</h1>
            </div>
            {
                companyData?.data?.map((obj) => {
                    return (
                        <div className={`${styles.boxShadow} flex flex-col gap-[16px] border-[1px] border-slate-400 rounded-[12px] p-[20px] mb-[24px]`}>
                            <div className='flex flex-row justify-between'>
                                <div className='flex flex-row gap-[16px]'>
                                    <div>
                                        <img src={egLabsLogo} alt="egLabsLogo" />
                                    </div>
                                    <div className='flex flex-col'>
                                        <h2 className='font-semibold text-[26px] leading-[36px]'>{obj?.companyname}</h2>
                                        <h3 className='font-normal text-[16px] leading-[22px]' >{obj?.companyLocation}</h3>
                                        <p className='text-[#666666] font-semibold text-[14px] leading-[19px]'>{obj?.companyType}</p>
                                    </div>
                                </div>
                                <div className='hidden md:block'>
                                    <Link to={`/${obj?.link}`}  ><button className={styles.viewProductsButton}>View Products</button></Link>
                                </div>
                            </div>
                            <div className='flex flex-row gap-[2px]'>
                                <p className='font-semibold text-[14px] leading-[19px]'>Domains:</p>
                                <p className='text-[#666666] font-semibold text-[14px] leading-[19px]'>{obj.domain}</p>
                            </div>
                            <div className=' md:hidden'>
                                <Link to={`/${obj?.link}`}  ><button className={styles.viewProductsButton}>View Products</button></Link>
                            </div>

                        </div>
                    )
                }
                )
            }


        </div>
    )
}

export default Companies