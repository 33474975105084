import React from 'react'
import SideImage from '../Assets/Images/SideImage.png'


function Landing() {
    return (
        <section>
            {/* <!-- Flex Container --> */}
            <div className="container flex flex-col-reverse items-start mb-[60px] mx-auto mt-[30px] md:mt-[140px] md:flex-row">
                {/* <!-- Left Item --> */}
                <div className='flex flex-col   md:text-left md:w-2/3'>
                    <h1 className="pb-[16px]  text-[24px] font-semibold leading-[33px] md:pb-[20px] md:text-[54px] md:leading-[74px] md:font-medium">Connecting Investors with Innovative IT Products: Discover the Next Big Thing</h1>
                    <p className="  text-[14px] font-normal leading-[20px] md:text-[16px] md:leading-[22px]">Welcome to Investcalls, the premier platform that bridges the gap between investors and groundbreaking startup products. Our platform is designed to empower investors by providing them with a curated selection of promising ventures and disruptive technologies. Discover the next big thing as you explore a diverse range of innovative startup products across various industries.</p>
                    {/* <!-- Button --> */}
                    <div className="flex  mt-[34px] md:justify-start md:mt-[54px]">
                        <a href="/investors"
                            className="px-[22px] py-[16px] text-white font-semibold text-[16px] leading-[19px] bg-[#2054E8] rounded-[5px] baseline hover:bg-[#3d68e3] md:block">Join as Investor</a>
                    </div>
                </div>
                {/* <!-- Image --> */}
                <div className='md:w-1/3 md:pt-[80px] pb-[30px] '>
                    <img src={SideImage} alt="" className='w-[100%]' />
                </div>
            </div>
        </section>
    )
}

export default Landing